import * as THREE from 'three';
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import init from './init';

import './style.css';


let currentScroll = 0; // Текущая позиция прокрутки
let targetScroll = 0; // Целевая позиция прокрутки

let start_pos_X =  0;
let start_pos_Y =  -0.5;
let start_pos_Z =  2.2;
let start_rotate_Y = 0;
let start_intensivity = 50;

let first_pos_X =  1;
let first_pos_Y =  -0.5;
let first_pos_Z =  1;
let first_rotate_Y = -0.7;

let second_pos_X =  5;
let second_pos_Y =  -0.5;
let second_pos_Z =  1;
let second_intensivity = 1;



let start_me_pos_X =  0;
let start_me_pos_Y =  -10;
let start_me_pos_Z =  -1;


let second_me_pos_X =  -2;
let second_me_pos_Y =  -1.5;
let second_me_pos_Z =  -1;







const { sizes, camera, scene, canvas, controls, renderer } = init();

camera.position.z = 3;

const dirlight = new THREE.DirectionalLight(0xffffff, start_intensivity);
dirlight.position.set(-8,12,0);
scene.add(dirlight);



const hemiLight = new THREE.HemisphereLight(0xffffff,0xffffff, 3);
hemiLight.position.set(0,50,0);
scene.add(hemiLight);

const loader = new GLTFLoader();
let model = null;
let model_me = null;

loader.load(
	'/models/tv/scene.gltf',
	(gltf) => {
		console.log('success');
		console.log(gltf);
		model = gltf.scene;
		model.position.set(start_pos_X,start_pos_Y,start_pos_Z);
		model.rotateY(start_rotate_Y);
		scene.add(gltf.scene);
	},
	(process) => {
		console.log('process');
		console.log(process);
	},
	(error) => {
		console.log('error');
		console.log(error);
	}
);

let mixer = null;
loader.load(
	'/models/me/scene.gltf',
	(gltf) => {
		console.log('success');
		console.log(gltf);
		model_me = gltf.scene;
		mixer = new THREE.AnimationMixer(model_me);
		const action = mixer.clipAction(gltf.animations[0]);
		action.play();
		model_me.position.set(start_me_pos_X,start_me_pos_Y,start_me_pos_Z);
		model_me.rotateY(1);
		scene.add(gltf.scene);
	},
	(process) => {
		console.log('process');
		console.log(process);
	},
	(error) => {
		console.log('error');
		console.log(error);
	}
);






// const geometry = new THREE.BoxGeometry(1, 1, 1);
// const material = new THREE.MeshBasicMaterial({
// 	color: 'gray',
// 	wireframe: true,
// });
// const mesh = new THREE.Mesh(geometry, material);
// scene.add(mesh);

// Функция для получения текущего "экрана" на основе прокрутки
const getCurrentScreen = () => {
    return Math.floor(window.scrollY / window.innerHeight);
};

const clock = new THREE.Clock();

const tick = () => {
		// Определяем текущий экран
	const currentScreen = getCurrentScreen();

	const factor = (window.scrollY % window.innerHeight) / window.innerHeight;
	controls.update();
	const delta = clock.getDelta();
	if(model_me){
		if(mixer){
			mixer.update(delta);
		}
		if (currentScreen == 1) {
			model_me.position.y = start_me_pos_Y + (second_me_pos_Y - start_me_pos_Y) * factor;
			model_me.position.x = start_me_pos_X + (second_me_pos_X - start_me_pos_X) * factor;
			model_me.position.z = start_me_pos_Z + (second_me_pos_Z - start_me_pos_Z) * factor;
		}else if(currentScreen == 2) {
			model_me.rotation.y = 1 + (-0.7 - 1) * factor;
		}else if(currentScreen == 4) {
			// model_me.position.y = second_me_pos_Y + (-10 -second_me_pos_Y) * factor;
			model_me.position.x = second_me_pos_X + (-30 -second_me_pos_X) * factor;
			// model_me.position.z = second_me_pos_Z + ( 0-second_me_pos_Z) * factor;
		}
	}



	if(model){
		if (currentScreen === 0) {
			// Находимся на стартовой позиции
			
			// Используем линейную интерполяцию для плавного перехода
			model.position.y = start_pos_Y + (first_pos_Y - start_pos_Y) * factor;
			model.position.x = start_pos_X + (first_pos_X - start_pos_X) * factor;
			model.position.z = start_pos_Z + (first_pos_Z - start_pos_Z) * factor;
			model.rotation.y = start_rotate_Y + (first_rotate_Y - start_rotate_Y) * factor;
		} else if (currentScreen == 1) {
			// Фиксируем модель на позиции первой страницы
			// model.position.set(first_pos_X, first_pos_Y, first_pos_Z);
			model.position.y = first_pos_Y + (second_pos_Y - first_pos_Y) * factor;
			model.position.x = first_pos_X + (second_pos_X - first_pos_X) * factor;
			model.position.z = first_pos_Z + (second_pos_Z - first_pos_Z) * factor;
			dirlight.intensity = start_intensivity + (second_intensivity - start_intensivity) * factor;
		}else if(currentScreen >2){

		}
	}



	renderer.render(scene, camera);
	window.requestAnimationFrame(tick);
};


tick();


window.addEventListener('scroll', () => {
	targetScroll = window.scrollY || document.documentElement.scrollTop;
  });

/** Базовые обпаботчики событий длы поддержки ресайза */
window.addEventListener('resize', () => {
	// Обновляем размеры
	sizes.width = window.innerWidth;
	sizes.height = window.innerHeight;

	// Обновляем соотношение сторон камеры
	camera.aspect = sizes.width / sizes.height;
	camera.updateProjectionMatrix();

	// Обновляем renderer
	renderer.setSize(sizes.width, sizes.height);
	renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
	renderer.render(scene, camera);
});

window.addEventListener('dblclick', () => {
	if (!document.fullscreenElement) {
		canvas.requestFullscreen();
	} else {
		document.exitFullscreen();
	}
});
